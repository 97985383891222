// routes
import { paths } from 'src/centity/routes/paths';

// API
// ----------------------------------------------------------------------

export const CENTITY_API = process.env.REACT_APP_CENTITY_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root;

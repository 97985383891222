import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'src/centity/auth/guard';
// layouts
import DashboardLayout from 'src/centity/layouts/dashboard';
// components
import { LoadingScreen } from 'src/centity/components/loading-screen';

// ----------------------------------------------------------------------

//
const HomePage = lazy(() => import('src/centity/pages/dashboard/home'));

//
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));

//
const UserAccountPage = lazy(() => import('src/centity/pages/dashboard/user/account'));

// HOA
const HOAListPage = lazy(() => import('src/centity/pages/dashboard/hoa/list'));
const HOACreatePage = lazy(() => import('src/centity/pages/dashboard/hoa/new'));
const HOAEditPage = lazy(() => import('src/centity/pages/dashboard/hoa/edit'));
const HOADetailsPage = lazy(() => import('src/centity/pages/dashboard/hoa/details'));

// BUILDING
const BuildingListPage = lazy(() => import('src/centity/pages/dashboard/building/list'));
const BuildingCreatePage = lazy(() => import('src/centity/pages/dashboard/building/new'));
const BuildingEditPage = lazy(() => import('src/centity/pages/dashboard/building/edit'));
const BuildingDetailsPage = lazy(() => import('src/centity/pages/dashboard/building/details'));

// RESIDENTIAL UNIT
const ResidentialUnitListPage = lazy(() =>
  import('src/centity/pages/dashboard/residential-unit/list')
);
const ResidentialUnitCreatePage = lazy(() =>
  import('src/centity/pages/dashboard/residential-unit/new')
);
const ResidentialUnitEditPage = lazy(() =>
  import('src/centity/pages/dashboard/residential-unit/edit')
);
const ResidentialUnitDetailsPage = lazy(() =>
  import('src/centity/pages/dashboard/residential-unit/details')
);

// RESIDENT
const ResidentPage = lazy(() => import('src/centity/pages/dashboard/user/resident'));

// CHAT
// const ChatPage = lazy(() => import('src/centity/pages/dashboard/chat'));

// Support
const SupportRequestListPage = lazy(() => import('src/centity/pages/dashboard/support/list'));
const SupportRequestCreatePage = lazy(() => import('src/centity/pages/dashboard/support/new'));
const SupportRequestDetailsPage = lazy(() => import('src/centity/pages/dashboard/support/details'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <HomePage />, index: true },
      {
        path: 'hoa',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <HOAListPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'list',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <HOAListPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <HOADetailsPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleBasedGuard hasContent roles={['administrator']}>
                <HOACreatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <HOAEditPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'buildings',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <BuildingListPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'list',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <BuildingListPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <BuildingDetailsPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <BuildingCreatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <BuildingEditPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      {
        path: 'residential-unit',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <ResidentialUnitListPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: 'list',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <ResidentialUnitListPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member']}
              >
                <ResidentialUnitCreatePage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <ResidentialUnitDetailsPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <ResidentialUnitEditPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      // {
      //   path: 'chat',
      //   element: (
      //     <RoleBasedGuard
      //       hasContent
      //       roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
      //     >
      //       <ChatPage />
      //     </RoleBasedGuard>
      //   ),
      // },

      {
        path: 'user',
        children: [
          // { element: <UserProfilePage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          {
            path: 'account',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <UserAccountPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'resident/:id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <ResidentPage />
              </RoleBasedGuard>
            ),
          },
        ],
      },

      {
        path: 'support',
        children: [
          {
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <SupportRequestListPage />
              </RoleBasedGuard>
            ),
            index: true,
          },
          {
            path: ':id',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <SupportRequestDetailsPage />
              </RoleBasedGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <RoleBasedGuard
                hasContent
                roles={['administrator', 'property_manager', 'hoa_board_member', 'resident']}
              >
                <SupportRequestCreatePage />
              </RoleBasedGuard>
            ),
          },
        ],
      },
    ],
  },
];

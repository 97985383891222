import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/centity/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);

        const user = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { access, user } = response.data;

    setSession(access);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // LOGIN BY CODE
  const loginByCodeRequest = useCallback(async (emailOrPhone) => {
    const data = {
      email_or_phone: emailOrPhone,
    };

    const response = await axios.post(endpoints.auth.loginByCode.requestCode, data);

    return {
      isSuccess: true,
      type: response.data.type,
    };
  }, []);

  const loginByCodeVerifyResponseHandling = useCallback(async (url, data) => {
    try {
      const response = await axios.post(url, data);
      if (!response) {
        return {
          isSuccess: false,
        };
      }

      const { access, user } = response.data;

      setSession(access);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      return {
        isSuccess: true,
        data: response.data,
      };
    } catch (e) {
      return {
        isSuccess: false,
      };
    }
  }, []);

  const loginByCodeVerifyEmail = useCallback(
    async (email, code) => {
      const data = {
        email,
        code,
      };

      const response = await loginByCodeVerifyResponseHandling(
        endpoints.auth.loginByCode.verifyCodeByEmail,
        data
      );
      return response;
    },
    [loginByCodeVerifyResponseHandling]
  );

  const loginByCodeVerifyPhone = useCallback(
    async (phone, code) => {
      const data = {
        phone,
        code,
      };

      const response = await loginByCodeVerifyResponseHandling(
        endpoints.auth.loginByCode.verifyCodeByPhone,
        data
      );
      return response;
    },
    [loginByCodeVerifyResponseHandling]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    const data = {
      email,
    };

    await axios.post(endpoints.auth.resetPassword.requestCode, data);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email, code, password) => {
    const data = {
      email,
      code,
      password,
    };

    await axios.post(endpoints.auth.resetPassword.resetPasswordByCode, data);
  }, []);

  //
  const customLogin = useCallback(async (accessToken, user) => {
    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginByCodeRequest,
      loginByCodeVerifyEmail,
      loginByCodeVerifyPhone,
      loginByCodeVerifyResponseHandling,
      register,
      logout,
      forgotPassword,
      newPassword,
      customLogin,
    }),
    [
      login,
      loginByCodeRequest,
      loginByCodeVerifyEmail,
      loginByCodeVerifyPhone,
      loginByCodeVerifyResponseHandling,
      logout,
      register,
      forgotPassword,
      newPassword,
      customLogin,
      state.user,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

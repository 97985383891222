import axios from 'axios';
// config
import { CENTITY_API } from 'src/centity/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: CENTITY_API });

axiosInstance.interceptors.response.use(
  (res) => {
    if (!res) {
      return res;
    }

    return {
      ...res,
      isSuccess: [200, 201, 202, 204].includes(res.status),
    };
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/auth/login/',
    loginByCode: {
      requestCode: '/auth/login-by-code/',
      verifyCode: '/auth/login-by-code/verify/',
      verifyCodeByEmail: '/auth/login-by-code/email/verify/',
      verifyCodeByPhone: '/auth/login-by-code/phone/verify/',
    },
    resetPassword: {
      requestCode: '/auth/password-reset-by-code/request/',
      resetPasswordByCode: '/auth/password-reset-by-code/',
    },
    me: '/auth/profile/',
    passwordSet: '/auth/password-set/',
    passwordChange: '/auth/password-change/',
    switchRole: '/auth/switch-role/',
  },
  entity: {
    hoa: {
      list: '/entities/hoas/',
      bulk_delete: (ids) => `/entities/hoas/delete/?pk=${ids}`,
      new: '/entities/hoas/',
      details: (id) => `/entities/hoas/${id}/`,
      delete: (id) => `/entities/hoas/${id}/`,
      defaultAccessToken: (id) => `/entities/hoas/${id}/access_tokens/get_default_token/`,
      buidings: (hoaId, token) => `/entities/hoas/${hoaId}/buildings/?token=${token}`,
    },
    verify_hoa_access_token: '/entities/hoa_access_tokens/verify/',
    buildings: {
      list: (hoaID) => {
        const url = '/entities/buildings/';
        return hoaID ? `${url}?hoa=${hoaID}` : url;
      },
      bulk_delete: (ids) => `/entities/buildings/delete/?pk=${ids}`,
      new: '/entities/buildings/',
      details: (id) => `/entities/buildings/${id}/`,
      delete: (id) => `/entities/buildings/${id}/`,
      defaultAccessToken: (id) => `/entities/buildings/${id}/access_tokens/get_default_token/`,
      exportComelit: (id) => `/entities/buildings/${id}/export_comelit/`,
    },
    verify_building_access_token: '/entities/building_access_tokens/verify/',
    residentialUnitType: {
      list: '/entities/residential_unit_types/',
    },
    residentialUnit: {
      list: (buildingId) => {
        const url = '/entities/residential_units/';
        return buildingId ? `${url}?building=${buildingId}` : url;
      },
      bulk_delete: (ids) => `/entities/residential_units/delete/?pk=${ids}`,
      new: '/entities/residential_units/',
      details: (id) => `/entities/residential_units/${id}/`,
      delete: (id) => `/entities/residential_units/${id}/`,
      defaultAccessToken: (id) =>
        `/entities/residential_units/${id}/access_tokens/get_default_token/`,
      deleteResident: (id, unitID) =>
        `/entities/residential_units/${unitID}/resident_mappings/${id}/`,
    },
    verify_unit_access_token: '/entities/unit_access_tokens/verify/',
  },
  user: {
    HOABoardMembers: {
      list: (id) => `/users/hoa_board_members/?hoa=${id}`,
      new: '/users/hoa_board_members/',
      invite: '/users/hoa_board_members/invite/',
      inviteConfirm: '/users/hoa_board_members/invite/confirm/',
      residents: (id) => `/users/hoa_board_member/${id}/residents/?hoa=${id}`,
      details: (id) => `/users/hoa_board_members/${id}/`,
    },
    residents: {
      list: (hoaId) => `/users/residents/?hoa=${hoaId}`,
      details: (id) => `/users/residents/${id}/`,
      invite: '/users/residents/invite/',
      unset_board_members: (id) => `/users/residents/${id}/unset_board_member/`,
      set_board_members: (id) => `/users/residents/${id}/set_board_member/`,
      register_hoa: '/users/residents/register/hoa/',
      register_hoa_verify: 'users/residents/register/hoa/verify/',
      register_building: '/users/residents/register/building/',
      register_unit: '/users/residents/register/unit/',
      verifyInvitation: '/users/residents/invite/verify/',
      confirmInvitation: '/users/residents/invite/confirm/',
    },
    residentMappings: {
      details: (id) => `/users/resident_mappings/${id}/`,
    },
    propertyManager: {
      list: (hoaId) => {
        const url = '/users/property_managers/';
        return hoaId ? `${url}?hoa=${hoaId}` : url;
      },
      details: (id) => `/users/property_managers/${id}/`,
      invite: '/users/property_managers/invite/',
      inviteConfirm: '/users/property_managers/invite/confirm/',
    },
    userInvitation: {
      list: (hoaId, userType) => `/users/user_invitations/?hoa=${hoaId}&user_type=${userType}`,
      details: (id) => `/users/user_invitations/${id}/`,
      resend: (id) => `/users/user_invitations/${id}/resend/`,
    },
  },
  chat: {
    accessToken: '/chat/access_token/',
  },
  support: {
    list: '/support/list/',
    new: '/support/create/',
    details: (id) => `/support/${id}/`,
  },
};

// i18n
import 'src/centity/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// MS Azure Tracking
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/centity/routes/sections';
// theme
import ThemeProvider from 'src/centity/theme';
// locales
import { LocalizationProvider } from 'src/centity/locales';
// hooks
import { useScrollToTop } from 'src/centity/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/centity/components/progress-bar';
import { MotionLazy } from 'src/centity/components/animate/motion-lazy';
import SnackbarProvider from 'src/centity/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/centity/components/settings';
// sections
import { CheckoutProvider } from 'src/centity/sections/checkout/context';
// auth
import { AuthProvider, AuthConsumer } from 'src/centity/auth/context';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------


// Enable Azure App Insights in production
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APP_INSIGHTS_CS,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});
if (process.env.NODE_ENV === 'production') {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'cyan', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <CheckoutProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    <Router />
                  </AuthConsumer>
                </CheckoutProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default withAITracking(reactPlugin, App);

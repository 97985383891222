import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/centity/auth/guard';
// layouts
// import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/centity/layouts/auth/classic';
// components
import { SplashScreen } from 'src/centity/components/loading-screen';

// ----------------------------------------------------------------------
const LoginPage = lazy(() => import('src/centity/pages/auth/login'));
const LoginByCodePage = lazy(() => import('src/centity/pages/auth/login-by-code'));
const ForgotPasswordPage = lazy(() => import('src/centity/pages/auth/forgot-password'));
const NewPasswordPage = lazy(() => import('src/centity/pages/auth/new-password'));
const ResidentRegisterHOAPage = lazy(() => import('src/centity/pages/auth/resident-register-hoa'));
const ResidentRegisterHOAVerifyPage = lazy(() =>
  import('src/centity/pages/auth/resident-register-hoa-verify')
);
const RegisterDonePage = lazy(() => import('src/centity/pages/auth/register-done'));
const ResidentRegisterBuildingPage = lazy(() =>
  import('src/centity/pages/auth/resident-register-building')
);
const ResidentRegisterUnitPage = lazy(() =>
  import('src/centity/pages/auth/resident-register-unit')
);
const InvitationConfirmPage = lazy(() =>
  import('src/centity/pages/auth/invitation-confirm')
);
const InvitationDonePage = lazy(() => import('src/centity/pages/auth/invitation-confirm-done'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthClassicLayout>
            <LoginPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'login-by-code',
        element: (
          <AuthClassicLayout>
            <LoginByCodePage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <AuthClassicLayout>
            <ForgotPasswordPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'new-password',
        element: (
          <AuthClassicLayout>
            <NewPasswordPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'register/resident/hoa/:hoa_access_token',
        element: (
          <div>
            <ResidentRegisterHOAPage />
          </div>
        ),
      },
      {
        path: 'register/resident/hoa/verify/:token',
        element: (
          <div>
            <ResidentRegisterHOAVerifyPage />
          </div>
        ),
      },
      {
        path: 'register/resident/done',
        element: (
          <div>
            <RegisterDonePage />
          </div>
        ),
      },
      {
        path: 'register/resident/building/:building_access_token',
        element: (
          <div>
            <ResidentRegisterBuildingPage />
          </div>
        ),
      },
      {
        path: 'register/resident/unit/:unit_access_token',
        element: (
          <div>
            <ResidentRegisterUnitPage />
          </div>
        ),
      },

      {
        path: 'invitation/verify/:token',
        element: (
          <div>
            <InvitationConfirmPage />
          </div>
        ),
      },
      {
        path: 'invitation/done',
        element: (
          <div>
            <InvitationDonePage />
          </div>
        ),
      },
    ],
  },
];

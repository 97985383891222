const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    loginByCode: `${ROOTS.AUTH}/login-by-code`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      resident: {
        details: (id) => `${ROOTS.DASHBOARD}/user/resident/${id}`,
      },
    },
    hoa: {
      root: `${ROOTS.DASHBOARD}/hoa`,
      new: `${ROOTS.DASHBOARD}/hoa/new`,
      details: (id) => `${ROOTS.DASHBOARD}/hoa/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/hoa/${id}/edit`,
    },
    buildings: {
      root: `${ROOTS.DASHBOARD}/buildings`,
      new: `${ROOTS.DASHBOARD}/buildings/new`,
      details: (id) => `${ROOTS.DASHBOARD}/buildings/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/buildings/${id}/edit`,
    },
    residentialUnit: {
      root: `${ROOTS.DASHBOARD}/residential-unit`,
      new: `${ROOTS.DASHBOARD}/residential-unit/new`,
      details: (id) => `${ROOTS.DASHBOARD}/residential-unit/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/residential-unit/${id}/edit`,
    },
    chat: `${ROOTS.DASHBOARD}/chat`,
    support: {
      root: `${ROOTS.DASHBOARD}/support`,
      new: `${ROOTS.DASHBOARD}/support/new`,
      details: (id) => `${ROOTS.DASHBOARD}/support/${id}`,
    },
  },
};
